import Splide from '@splidejs/splide';
const opinionsSlider = document.querySelectorAll('[data-opinions-slider]');

if (opinionsSlider) {
    opinionsSlider.forEach(item => {

        let sliderSplide = new Splide(item, {
            perPage: 2,
            focus: 0,
            gap: 16,
            rewind: false,
            pagination: false,
            arrows: false,
            breakpoints: {
                1000: {
                    perPage: 1.2,
                },
            },
        });
        sliderSplide.mount();
    });
}


